<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization, PostApiWithAuthorizationAction } from '../../../helpers/apiActions';
import ManageBinLabel from '../../drawers/product/manageBinLabel.vue';
import ManageBin from '../../drawers/product/manageBin.vue';
import AddItemsToBin from '../../drawers/product/addItemsToBin.vue';
import ViewBinItemsList from '../../drawers/product/viewBinItemsList.vue';
import { isMobile, isTablet } from 'mobile-device-detect';

export default {
  name: 'BinPage',
  components: { ViewBinItemsList, ManageBinLabel, ManageBin, AddItemsToBin },
  props: {},
  data() {
    return {
        displayBinItemPopup: false,
        displayManageLabelsPopup: false,
        displayManageBinPopup: false,
        displayAddItemsToBinPopup: false,
        formBinNumber: '0',
        tableData: [],
        labelData: {},
        selectedBinId: 0,
        formFilter: {
            searchQuery: ''
        },
        pagination: {
            page_size: 50,
            total: 1,
            page: 1
        },
        columns: [
            {
                key: "bin",
                value: 'BIN',
                sortable: false,
            },
            {
                key: "actions",
                value: 'Actions',
                sortable: false,
                center: true
            }
        ],
    }
  },
  mixins: [
    common
  ],
  methods: {
    addLabel (id) {
        let _vm = this
        _vm.selectedBinId = id
        _vm.loadSingleData(id).then(data => {
            _vm.displayManageLabelsPopup = true
            _vm.labelData = data
        })     
    },
    view (id) {
        let _vm = this
        _vm.selectedBinId = id
        _vm.displayBinItemPopup = true
    },
    remove (data) {
        let _vm = this
        if (confirm('Are you sure that you want to remove this BIN# ?')) {
            PostApiWithAuthorizationAction("admin/bin/delete/" + data.id).then(() => {
                _vm.tableData = _vm.tableData.filter(v=>v.id!=data.id)
            });
        }
    },
    search() {
        let _vm = this
        if (_vm.formFilter.searchQuery.length > 0 && !isNaN(_vm.formFilter.searchQuery)) {
            _vm.loadPageData()
        } else {
            _vm.$toast.error("Invalid BIN Number", {
                position: "top-right",
                timeout: 1000,
                closeOnClick: true
            });
        }
    },
    updateCurrentPage(page) {
        this.loadPageData({ page })
        let url = window.location.href.split(/[?#]/)[0];    
        url += (url.indexOf('?') > -1) ? "&" : "?";
        url += "page=" + page
        window.history.replaceState({}, "Cybershop v2", url);
    },
    loadPageData(params = { page: 1 }) {
        let _vm = this
        return PostApiWithAuthorizationAction("admin/bin/list", {
            page: params.page,
            query: _vm.formFilter.searchQuery
        }).then(res => {
            _vm.tableData = res.data
            _vm.pagination.page_size = res.page_size
            _vm.pagination.total = res.count
            if (res.data.length > 0) {
                _vm.pagination.page = Number(params.page)
            }
        });
    },
    loadSingleData(id) {
        return GetApiActionWithAuthorization("admin/bin/load-particular-data/" + id)
        .then(res => {
            return res
        });
    },
    viewBin(id) {
        let _vm = this
        _vm.selectedBinId = id
        _vm.displayAddItemsToBinPopup = true
    },
    reset() {
        let page = this.$route.query.page || 1
        this.loadPageData({ page })
    }
  },
  computed: {
    manageBinLabelDrawerSize () {
      return isMobile ? '100%' : isTablet ? '50%' : '30%'
    },
  },
  mounted () {
    let _vm = this
    let page = this.$route.query.page || 1
    _vm.loadPageData({ page })
  },
  watch: {}
}
</script>

<template>
<div class="barcode__page_content">
    <el-drawer
      ref="addItemsToBinDrawer"
      :title="'Add Barcodes to B' + formatBinNumber(selectedBinId)"
      :visible.sync="displayAddItemsToBinPopup"
      :close-on-press-escape="false"
      :with-header="true"
      :wrapperClosable="false"
      :size="manageBinLabelDrawerSize"
      :append-to-body="true"
      :destroy-on-close="true"
      custom-class="common-drawer demo-drawer">
      <AddItemsToBin :binNumber="selectedBinId"/>
    </el-drawer>
    <el-drawer
      ref="manageBinDrawer"
      title="BIN Details"
      :visible.sync="displayManageBinPopup"
      :close-on-press-escape="false"
      :with-header="true"
      :wrapperClosable="false"
      :size="manageBinLabelDrawerSize"
      :append-to-body="true"
      :destroy-on-close="true"
      custom-class="common-drawer demo-drawer">
      <ManageBin nextDrawerLabel="manageBin"/>
    </el-drawer>
    <el-drawer
      ref="manageBinLabelDrawer"
      :title="'Manage Bin Labels: B' + formatBinNumber(selectedBinId)"
      :visible.sync="displayManageLabelsPopup"
      :close-on-press-escape="false"
      :with-header="true"
      :wrapperClosable="false"
      :size="manageBinLabelDrawerSize"
      :append-to-body="true"
      :destroy-on-close="true"
      custom-class="common-drawer demo-drawer">
      <ManageBinLabel :data="labelData"/>
    </el-drawer>
    <el-drawer
      ref="binItemDrawer"
      :title="'Bin Items: B' + formatBinNumber(selectedBinId)"
      :visible.sync="displayBinItemPopup"
      :close-on-press-escape="false"
      :with-header="true"
      :wrapperClosable="false"
      size="70%"
      :append-to-body="true"  
      custom-class="common-drawer demo-drawer">
      <ViewBinItemsList
        :binId="selectedBinId"/>
    </el-drawer>
    <div class="my-3">
        <b-button
            variant="warning"
            @click="displayManageBinPopup=true"
        >
        <i class="fas fa-boxes"></i> Manage BIN
        </b-button>
    </div>
    <div class="search-bin">
        <el-form
            ref="form"
            :model="formFilter"
            :inline="true"
            @submit.native.prevent="search()"
            class="form-inline-custom mb-0 m-3"
        >
            <el-form-item class="mr-0 border-green" style="border-color: #50a5f1">
                <el-input
                    placeholder="Find a BIN#"
                    class="border-empty"
                    v-model="formFilter.searchQuery"
                    @clear="reset()"
                    clearable>
                </el-input>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="success"
                    native-type="submit"
                    icon="el-icon-search"
                    class="border-radius-empty"
                    style="background: #50a5f1;padding: 13px 20px;border-color: #50a5f1"
                ></el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="table-responsive">
        <table
            role="table"
            aria-busy="false"
            aria-colcount="10"
            class="table b-table"
            >
                <thead
                role="rowgroup"
                class=""
                style="background-color: #74788d; color: white"
                >
                <tr role="row" class="">
                    <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    :class="[
                        column.center ? 'text-center': '',
                        'px-4'
                    ]"
                    v-for="column in columns"
                    :key="column.key"
                    >
                    <div>{{ column.value }}</div>
                    </th>
                </tr>
                </thead>
                <tbody role="rowgroup">
                <tr
                    role="row"
                    aria-rowindex="1"
                    class=""
                    v-for="data in tableData"
                    :key="data.id"
                >
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px"
                        class="col-sm">
                        <button class="btn btn-primary" @click="viewBin(data.binNumber)">
                            <i class="fas fa-box"></i> B{{ formatBinNumber(data.binNumber) }}
                        </button>
                    </td>
                    <td
                        aria-colindex="2"
                        role="cell"
                        :class="['text-center', 'px-0', 'col-sm']">
                        <button
                            class="btn btn-rounded btn-info"
                            style="margin-right: 5px"
                            @click="addLabel(data.binNumber)">
                            <i class="fas fa-tags"></i>
                        </button>
                        <button
                            class="btn btn-rounded btn-dark"
                            style="margin-right: 5px"
                            @click="view(data.binNumber)">
                            <i class="far fa-eye"></i>
                        </button>
                        <button
                            class="btn btn-rounded btn-danger"
                            @click="remove(data)">
                            <i class="fas fa-trash-alt"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="pagination.total"
            :page-size="pagination.page_size"
            @current-change="updateCurrentPage"
            :current-page.sync="pagination.page"
            :pager-count="5"
            v-if="tableData.length > 0">
        </el-pagination>
    </div>
</div>
</template>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
}
@media (min-width: 768px) {
    .b-table {
        width: 30%;
    }
}
</style>
<style>
    .el-pagination.is-background .el-pager li,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .btn-next  {
        border-radius: 20px !important;
        min-width: 34px;
        height: 32px;
        line-height: 34px;
    }
</style>