<script>
import {  common } from '@/mixins/common';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import BarodePage from "@/components/pages/barcode/barcode";
import BinPage from "@/components/pages/barcode/bin";

export default {
  name: 'BarcodeList',
  components: {
    Layout,
    PageHeader,
    BarodePage,
    BinPage
  },
  props: {},
  data() {
    return {
        title: '',
        tab: '',
        pageFullyLoaded: true,
        onPageTabLoad: true,
        items: [],
        tabs: [
            {
                value: 'Barcodes',
                link: 'barcode/index',
                label: 'barcode_index',
                icon: 'fas fa-list',
                active: false
            },
            {
                value: 'BIN',
                link: 'barcode/bins',
                label: 'barcode_bins',
                icon: 'fas fa-list',
                active: false
            }
        ]
    }
  },
  mixins: [
    common
  ],
  methods: {
    preventTabClick(tab, oldTab) {
      if (typeof oldTab === 'string' && oldTab.length > 1) {
        location.href = '/' + tab.replaceAll('_', '/')
      }
      return this.onPageTabLoad && (oldTab == 0 || !oldTab);
    },
    handleTabClick() {
      this.onPageTabLoad = false
      return this.onPageTabLoad;
    }
  },
  computed: {},
  mounted () {
    let _vm = this
    _vm.tab = _vm.$route.path.replaceAll('/', '_').slice(1)  || 'barcode_index'
    let findActiveTabIndex = _vm.tabs.findIndex(m=>m.label==_vm.tab) || 0
    _vm.$set(_vm.tabs, findActiveTabIndex, Object.assign(_vm.tabs[findActiveTabIndex], {
        active: true
    }));
    _vm.title = _vm.tabs[findActiveTabIndex].value
  }
}
</script>
<template>
    <div class="barcode__content">
        <Layout>
            <PageHeader
                :title="title"
                :items="items"
                icon="bx bx-barcode"
                v-if="title.length > 0 && pageFullyLoaded"/>
            <div class="setting-page-container" v-if="pageFullyLoaded">
                <el-tabs
                    type="border-card"
                    v-model="tab"
                    :before-leave="preventTabClick"
                    @tab-click="handleTabClick">
                    <el-tab-pane
                      v-for="_tab in tabs"
                      :key="_tab.link"
                      :active="_tab.active"
                      :name="_tab.label"
                    >
                        <template #label>
                          <i :class="['font-size-16', _tab.icon]"></i> {{ _tab.value }}
                        </template>
                        <BarodePage v-if="tab==_tab.label && tab=='barcode_index'"/>
                        <BinPage v-if="tab==_tab.label && tab=='barcode_bins'"/>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </Layout>
    </div>
</template>
<style>
    .col-sm {
        padding: 0.4em 0.75em !important;
        vertical-align: middle;
    }
</style>

 