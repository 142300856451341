<script>
import {  common } from '@/mixins/common';
import { PostApiWithAuthorizationAction } from '../../../helpers/apiActions';
import InputTag from 'vue-input-tag'

export default {
  name: 'ManageBinLabel',
  components: {
    InputTag
  },
  props: {
    data: {
        type: Object,
        required: true
    }
  },
  data() {
    return {
        form: {
            labels: []
        },
        suggestions: []
    }
  },
  mixins: [
    common
  ],
  methods: {
    addItemValue (label) {
        if (!this.form.labels.includes(label))
            this.form.labels.push(label)
    }
  },
  computed: {},
  mounted () {
    let _vm = this
    for (const label of _vm.data.labels) {
        _vm.form.labels.push(label.label)
    }
    _vm.suggestions = _vm.data.suggestions
  },
  watch: {
    'form.labels': function(tags) {
      return PostApiWithAuthorizationAction("admin/bin/submit-labels/" + this.data.data.id, { tags })
    }
  }
}
</script>

<template>
<div class="products__list_content px-2">
    <div class="text-center">
        <input-tag
          v-model="form.labels"
          :limit="8"></input-tag>
        <small id="emailHelp" class="text-muted">You are allowed to add more tags at a time by typing <strong style="color: red">ENTER</strong>.</small>
    </div>
    <div class="box-section my-3">
        <div class="box-section-header">TAGS SUGGESTIONS</div>
        <div class="box-section-body pt-2">
            <el-tag
                v-for="(suggestion, index) in suggestions"
                :key="index"
                type="danger"
                size="small"
                effect="dark"
                style="margin-right: 5px; cursor: pointer;"
                @click="addItemValue(suggestion.label)">
                {{ suggestion.label }}
            </el-tag>
        </div>
    </div>
</div>
</template>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
}
</style>
<style>
</style>