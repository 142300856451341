<script>
import {  common } from '@/mixins/common';
import { GetApiActionWithAuthorization } from '../../../helpers/apiActions';

export default {
  name: 'BarcodePage',
  components: {},
  props: {},
  data() {
    return {
        tableData: [],
        isBarcodeGenerated: false,
        pagination: {
            page_size: 50,
            total: 1,
            page: 1
        },
        columns: [
            {
                key: "name",
                value: 'Name',
                sortable: false,
            },
            {
                key: "actions",
                value: 'Actions',
                sortable: false,
                center: true
            }
        ],
    }
  },
  mixins: [
    common
  ],
  methods: {
    updateCurrentPage(page) {
        this.loadPageData({ page })
        let url = window.location.href.split(/[?#]/)[0];    
        url += (url.indexOf('?') > -1) ? "&" : "?";
        url += "page=" + page
        window.history.replaceState({}, "Cybershop v2", url);
    },
    generate() {
        let _vm = this
        _vm.isBarcodeGenerated = true
        return GetApiActionWithAuthorization("admin/barcode/generate").then(res => {
            if (res.status == 'success') {
                location.href = '/barcode/index';
            } else {
                _vm.$toast.error(res.message, {
                    position: "top-right",
                    timeout: 1000,
                    closeOnClick: true
                });
            }
            _vm.isBarcodeGenerated = false
        });
    },
    print (rowIndex) {
        let _vm = this,
            item = _vm.tableData[rowIndex]
            _vm.tableData[rowIndex] = {
                ... _vm.tableData[rowIndex],
                printing: true
            }
        return GetApiActionWithAuthorization("admin/barcode/print/" + item.id).then(res => {
            if (res.status == 'success') {
                location.href = '/barcode/index';
            } else {
                _vm.$toast.error(res.message, {
                    position: "top-right",
                    timeout: 1000,
                    closeOnClick: true
                });
            }
        }).finally(() => {
            _vm.tableData[rowIndex].printing = false
        });
    },
    loadPageData(params = { page: 1 }) {
        let _vm = this
        return GetApiActionWithAuthorization("admin/barcode/list?page=" + params.page).then(res => {
            _vm.tableData = res.data
            _vm.pagination.page_size = res.page_size
            _vm.pagination.total = res.count
            if (res.data.length > 0) {
                _vm.pagination.page = params.page
            }
        });
    }
  },
  computed: {},
  mounted () {
    let page = this.$route.query.page || 1
    this.loadPageData({ page })
  },
  watch: {}
}
</script>

<template>
<div class="barcode__page_content">
    <div class="my-3">
        <b-button
            variant="primary"
            @click="generate()"
            v-if="!isBarcodeGenerated"
        >
        <i class="fas fa-tools"></i> Generate
        </b-button>
        <b-button
            variant="primary"
            :disabled="true"
            v-else
        >
        <i class="el-icon-loading"></i> Generating
        </b-button>
    </div>
    <div class="table-responsive">
        <table
            role="table"
            aria-busy="false"
            aria-colcount="10"
            class="table b-table"
            >
                <thead
                role="rowgroup"
                class=""
                style="background-color: #74788d; color: white"
                >
                <tr role="row" class="">
                    <th
                    role="columnheader"
                    scope="col"
                    aria-colindex="1"
                    :class="[
                        column.center ? 'text-center': '',
                        'px-4'
                    ]"
                    v-for="column in columns"
                    :key="column.key"
                    >
                    <div>{{ column.value }}</div>
                    </th>
                </tr>
                </thead>
                <tbody role="rowgroup">
                <tr
                    role="row"
                    aria-rowindex="1"
                    class=""
                    v-for="(data, index) in tableData"
                    :key="data.id"
                >
                    <td
                        aria-colindex="1"
                        role="cell"
                        style="font-size: 14px"
                        class="col-sm">
                        <b>{{ data.name }}</b>
                    </td>
                    <td
                        aria-colindex="2"
                        role="cell"
                        :class="['text-center', 'px-0', 'col-sm']">
                        <div class="mb-1">
                            <a
                                :href="data.fileName"
                                target="_blank"
                                v-if="data.printed"
                                class="btn btn-success btn-generated-pdf">
                                <i class="fas fa-file-pdf"></i>
                            </a>
                            <a
                                href="javascript:;"
                                class="btn btn-primary btn-generate-pdf"
                                @click="print(index)"
                                :disabled="data.printing"
                                v-else>
                                <i class="fas fa-file-pdf"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <el-pagination
            background
            layout="prev, pager, next"
            :total="pagination.total"
            :page-size="pagination.page_size"
            @current-change="updateCurrentPage"
            :current-page.sync="pagination.page"
            v-if="tableData.length > 0">
        </el-pagination>
    </div>
</div>
</template>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
}
@media (min-width: 768px) {
    .b-table {
        width: 40%;
    }
}
@media (min-width: 1025px) and (max-width: 1280px) {
    .b-table {
        width: 50%;
    }
}
</style>
<style>
    .el-pagination.is-background .el-pager li,
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .btn-next  {
        border-radius: 20px !important;
        min-width: 34px;
        height: 32px;
        line-height: 34px;
    }
</style>